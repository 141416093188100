* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, .App {
  position: relative;
  height: 100vh;
  direction: RTL;
  width: 100%;
  background-color: white;
}

body > main, .App > main {
  position: relative;
  z-index: 0;
  font-family: 'OpenL';
  margin: 0;
  width: 100%;
}

body a, .App a {
  text-decoration: none;
  color: black;
}

.fa-trash-alt {
  cursor: pointer;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenB";
  src: url("../fonts/Heebo-Bold.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenT";
  src: url("../fonts/Heebo-Thin.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scrollbar-width: thin;
}

main {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
}

main div, main a {
  font-family: 'OpenR';
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

main > .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  z-index: 1;
}

.buttonCon {
  position: relative;
  width: auto;
  height: auto;
  margin: 0.5rem 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.buttonCon > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin: 0.5rem 0;
  font-family: 'OpenR';
  background-color: #43547A;
  border: 0.1px solid #43547A;
  color: white;
  padding: 0.5rem 3rem;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.buttonCon > button:hover {
  background-color: white;
  border: 0.1px solid #43547A;
  color: #43547A;
}

.mob {
  display: none;
}

@media (max-width: 600px) {
  .mob {
    display: inline;
  }
}

.desk {
  display: inline;
}

@media (max-width: 600px) {
  .desk {
    display: none;
  }
}
